import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiHttpService, CAREFLOW_API_URL } from '@abbadox-monorepo/core-http-client';
import {
  AuthTokenResponse,
  CareflowAuthTokenResponse,
  LoginUserDto,
  LoginUserDto as LoginUserRequest,
  Profile,
} from '@abbadox-monorepo/kiosk-core-api-interfaces';

/**
 * Authentication service handling login.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthHttpClientService {
  private readonly apiHttpService = inject(ApiHttpService);
  private readonly http = inject(HttpClient);
  private readonly careflowApiUrl = inject(CAREFLOW_API_URL);

  login(credentials: LoginUserDto): Observable<AuthTokenResponse> {
    return this.apiHttpService.post<AuthTokenResponse, LoginUserRequest>('/auth/login', credentials);
  }

  generateCareflowToken(credentials: LoginUserDto): Observable<CareflowAuthTokenResponse> {
    return this.http.post<CareflowAuthTokenResponse>(`${this.careflowApiUrl}/KioskAuth`, JSON.stringify(credentials), {
      headers: this.headers,
    });
  }

  profile(): Observable<Profile> {
    return this.apiHttpService.get<Profile>('/users/profile');
  }

  get headers(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    return new HttpHeaders(headersConfig);
  }
}
