import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs';

import { AuthApiActions, selectProfileLoaded } from '../auth.state';

export const profileGuard: CanActivateFn = () => {
  const store = inject(Store);

  return store.select(selectProfileLoaded).pipe(
    tap((loaded) => {
      if (!loaded) {
        store.dispatch(AuthApiActions.loadProfileAttempted());
      }
    }),
    filter((loaded) => loaded),
    take(1),
  );
};
