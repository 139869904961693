import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';

import { CoreUiActions } from '@abbadox-monorepo/core-data-access';
import { ErrorHandlerActions } from '@abbadox-monorepo/core-error-handler';
import { CAREFLOW_USER } from '@abbadox-monorepo/core-http-client';
import { IdleActions } from '@abbadox-monorepo/core-idle';
import { LocalStorageService } from '@abbadox-monorepo/core-utils';
import {
  LOCAL_STORAGE_KIOSK_CAREFLOW_TOKEN_KEY,
  LOCAL_STORAGE_KIOSK_PROFILE_KEY,
  LOCAL_STORAGE_KIOSK_TOKEN_KEY,
} from '@abbadox-monorepo/kiosk-core-constants';
import { notificationStatusColorMap } from '@abbadox-monorepo/shared-ui';

import { AuthApiActions } from './auth.state';
import { AuthHttpClientService } from './services/auth-http-client.service';

/** Performs login in the app. */
export const loginUser$ = createEffect(
  (
    actions$ = inject(Actions),
    authHttpClientService = inject(AuthHttpClientService),
    localStorageService = inject(LocalStorageService),
  ) =>
    actions$.pipe(
      ofType(AuthApiActions.login),
      switchMap(({ username, password }) =>
        authHttpClientService.login({ username, password }).pipe(
          tap(({ access_token }) => localStorageService.setItem(LOCAL_STORAGE_KIOSK_TOKEN_KEY, access_token)),
          mergeMap(({ access_token }) => [
            AuthApiActions.loginSuccess({ access_token }),
            AuthApiActions.loadProfileAttempted(),
          ]),
          catchError((error: HttpErrorResponse) => of(AuthApiActions.loginFailed({ error: error.message }))),
        ),
      ),
    ),
  { functional: true },
);

/** Gets the user's profile details. */
export const loadProfile$ = createEffect(
  (actions$ = inject(Actions), authHttpClientService = inject(AuthHttpClientService)) =>
    actions$.pipe(
      ofType(AuthApiActions.loadProfileAttempted),
      switchMap(() =>
        authHttpClientService.profile().pipe(
          map((profile) => AuthApiActions.loadProfileSuccess({ profile })),
          catchError((error: HttpErrorResponse) => of(AuthApiActions.loadProfileFailed({ error: error.message }))),
        ),
      ),
    ),
  { functional: true },
);

/** Gets the user's profile details. */
export const loginCareflowUser$ = createEffect(
  (
    actions$ = inject(Actions),
    authHttpClientService = inject(AuthHttpClientService),
    careflowUser = inject(CAREFLOW_USER),
    localStorageService = inject(LocalStorageService),
  ) =>
    actions$.pipe(
      ofType(AuthApiActions.loginSuccess),
      switchMap(() =>
        authHttpClientService.generateCareflowToken(careflowUser).pipe(
          tap(({ token }) => localStorageService.setItem(LOCAL_STORAGE_KIOSK_CAREFLOW_TOKEN_KEY, token)),
          map(({ token }) => AuthApiActions.loginCareflowSuccess({ token })),
          catchError((error: HttpErrorResponse) => of(AuthApiActions.loginCareflowFailed({ error: error.message }))),
        ),
      ),
    ),
  { functional: true },
);

/** Navigate to the "home" view after login success. */
export const routeOnLoginSuccess$ = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(AuthApiActions.loginSuccess),
      tap(() => router.navigate(['/home'])),
    ),
  { functional: true, dispatch: false },
);

/** Notifiy the user when authentication fails. */
export const notifyLoginFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(AuthApiActions.loginFailed),
      map(() =>
        CoreUiActions.triggerToastOpen({
          data: {
            ...notificationStatusColorMap.get('error'),
            label: 'Authentication Failed',
            messages: ['Please check your username and password are correct and try again.'],
          },
        }),
      ),
    ),
  { functional: true },
);

/** Notifiy the user when authentication fails. */
export const clearAuthCacheOnInterceptor401Error$ = createEffect(
  (actions$ = inject(Actions), localStorageService = inject(LocalStorageService)) =>
    actions$.pipe(
      ofType(ErrorHandlerActions.handleError401),
      tap(() => {
        localStorageService.removeItem(LOCAL_STORAGE_KIOSK_TOKEN_KEY);
      }),
    ),
  { functional: true, dispatch: false },
);

/** Logout from the app. */
export const logout$ = createEffect(
  (actions$ = inject(Actions), localStorageService = inject(LocalStorageService), router = inject(Router)) =>
    actions$.pipe(
      ofType(AuthApiActions.logout),
      tap(() => {
        localStorageService.removeItem(LOCAL_STORAGE_KIOSK_TOKEN_KEY);
        localStorageService.removeItem(LOCAL_STORAGE_KIOSK_PROFILE_KEY);
        localStorageService.removeItem(LOCAL_STORAGE_KIOSK_CAREFLOW_TOKEN_KEY);
        router.navigate(['/login']);
      }),
      map(() => IdleActions.stopIdleWatch()),
    ),
  { functional: true },
);
