import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { filterByURLs, LocalStorageService } from '@abbadox-monorepo/core-utils';
import { CAREFLOW_API_ENDPOINTS, LOCAL_STORAGE_KIOSK_CAREFLOW_TOKEN_KEY } from '@abbadox-monorepo/kiosk-core-constants';

/**
 * Token interceptor that applies a stored access token to each API request.
 */
export const careflowTokenInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next: HttpHandlerFn) => {
  let careflowToken: string | null = null;

  inject(LocalStorageService)
    .getItem<string>(LOCAL_STORAGE_KIOSK_CAREFLOW_TOKEN_KEY)
    .subscribe((t) => (careflowToken = t));

  if (careflowToken && filterByURLs(request.url, CAREFLOW_API_ENDPOINTS)) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${careflowToken}`,
      },
    });
  }

  return next(request);
};
