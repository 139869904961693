import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';

import { AuthTokenResponse, CareflowAuthTokenResponse, Profile } from '@abbadox-monorepo/kiosk-core-api-interfaces';

export const AuthApiActions = createActionGroup({
  source: 'Auth API',
  events: {
    login: props<{ username: string; password: string }>(),
    loginSuccess: props<AuthTokenResponse>(),
    loginFailed: props<{ error: string }>(),
    loginCareflowSuccess: props<CareflowAuthTokenResponse>(),
    loginCareflowFailed: props<{ error: string }>(),
    loadProfileAttempted: emptyProps(),
    loadProfileSuccess: props<{ profile: Profile }>(),
    loadProfileFailed: props<{ error: string }>(),
    logout: emptyProps(),
  },
});

export type UserState = Profile & { access_token: string; careflow_token: string };

export const initialUserState: UserState = {
  accountConfigId: 0,
  accountName: '',
  username: '',
  logo: null,
  deviceUID: null,
  kioskConfigurationId: 0,
  isDeviceLock: null,
  deviceLocations: [],
  access_token: '',
  careflow_token: '',
};

export interface AuthState {
  user: UserState;
  authenticated: boolean;
  profileLoaded: boolean;
}

export const initialAuthState: AuthState = {
  user: initialUserState,
  authenticated: false,
  profileLoaded: false,
};

export const authFeature = createFeature({
  name: 'auth',
  reducer: createReducer(
    initialAuthState,
    on(AuthApiActions.login, (state) => ({
      ...state,
      authenticated: false,
    })),
    on(AuthApiActions.loginSuccess, (state, { access_token }) => ({
      ...state,
      user: {
        ...state.user,
        access_token,
      },
    })),
    on(AuthApiActions.loadProfileSuccess, (state, { profile }) => ({
      ...state,
      user: {
        ...state.user,
        ...profile,
      },
      profileLoaded: true,
    })),
    on(AuthApiActions.loginCareflowSuccess, (state, { token }) => ({
      ...state,
      user: {
        ...state.user,
        careflow_token: token,
      },
      authenticated: Boolean(token),
    })),
    on(AuthApiActions.logout, (state) => (state = initialAuthState)),
  ),
  extraSelectors: ({ selectUser }) => ({
    selectAccountName: createSelector(selectUser, (profile) => profile.accountName),
    selectProfile: createSelector(selectUser, ({ access_token, ...profile }) => profile),
    selectLogo: createSelector(selectUser, (user) => user.logo),
    selectDeviceLocations: createSelector(selectUser, (user) =>
      user.deviceLocations.map((l) => l.schedulerLocationId).join(','),
    ),
  }),
});

export const {
  name: AUTH_STATE_FEATURE_KEY,
  reducer: authReducer,
  selectAuthenticated,
  selectProfile,
  selectProfileLoaded,
  selectAccountName,
  selectLogo,
  selectUser,
  selectDeviceLocations,
} = authFeature;
