// Auth
export const LOCAL_STORAGE_KIOSK_TOKEN_KEY = 'kiosk_token';
export const LOCAL_STORAGE_KIOSK_CAREFLOW_TOKEN_KEY = 'kiosk__careflow_token';
export const LOCAL_STORAGE_KIOSK_PROFILE_KEY = 'kiosk_profile';
export const LOCAL_STORAGE_KIOSK_WORKFLOWS_KEY = 'kiosk_workflows';

// State Keys
export const STATE_KEYS = {
  AUTH_STATE_CACHE_KEY: 'auth_state',
  WORKFLOWS_STATE_CACHE_KEY: 'workflows_state',
};

// Timer$
export const STARTING_VALUE_TO_EMIT = 1;
export const INTERVAL_TO_EMIT_IN_MILLISECONDS = 1000; // 1 second

// Toast
export const TOAST_DISMISS_DURATION_IN_MILLISECONDS = 3000; // 3 seconds

// Format Patterns
/**
 * Note: date formatters are used by `date-fns`. Create another const for each unique format.
 */
export const DATE_OF_BIRTH_STANDARD_FORMAT = 'MM/dd/yyyy';

// Careflow API Endpoints
export const CAREFLOW_API_ENDPOINTS = ['KioskAuth'];
